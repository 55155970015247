<template>
    <div>
        <div class="header">
            <div class="logo">
                <img src="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/header/mi-logo.png?a=12" alt="mi">
                <!-- <span>小米支付</span> -->
            </div>
            <ul>
                <li :class="activedRoute === 'Home' ? 'selected' : ''" @click="link('Home')">概览</li>
                <li :class="activedRoute === 'BusCard' ? 'selected' : ''" @click="link('BusCard')">交通卡</li>
                <li :class="activedRoute === 'Mipay' ? 'selected' : ''" @click="link('Mipay')">Mi Pay</li>
                <li :class="activedRoute === 'DoorCard' ? 'selected' : ''" @click="link('DoorCard')">门卡·校园卡</li>
                <li :class="activedRoute === 'Car' ? 'selected' : ''" @click="link('Car')">车钥匙</li>
                <!-- <li :class="activedRoute === 'Ceb' || activedRoute === 'Gf' ? 'selected' : ''" @click="link('Gf')">信用卡</li> -->
                <li><a :href="helpLink" target="_blank">常见问题</a></li>
                <!-- <li><a href="https://m.pay.xiaomi.com" target="_blank">我的钱包</a></li> -->
            </ul>
        </div>
        <!-- <div v-show="activedRoute === 'Ceb' || activedRoute === 'Gf'" class="sub-header">
            <h3>小米信用卡</h3>
            <ul class="cards">
                <li :class="activedRoute === 'Gf' ? 'active' : ''" @click="link('Gf')">广发小米联名信用卡</li>
                <li :class="activedRoute === 'Ceb' ? 'active' : ''" @click="link('Ceb')">光大小米联名信用卡</li>
            </ul>
        </div> -->
    </div>
</template>

<script>
const defaultHelpLink = 'https://help.jr.mi.com/?app=com.mipay.wallet#/'
const helpLinkObj = {
    'Home': defaultHelpLink,
    'BusCard': 'https://help.jr.mi.com/?app=com.mipay.wallet#/e/EWALLET_FAQ_BUS',
    'Mipay': 'https://help.jr.mi.com/?app=com.mipay.wallet#/e/EWALLET_FAQ_MIPAY',
    'DoorCard': 'https://help.jr.mi.com/?app=com.mipay.wallet#/e/EWALLET_FAQ_DOOR',
    'Ceb': 'https://help.jr.mi.com/?app=com.mipay.wallet#/p/267',
    'Gf': 'https://help.jr.mi.com/?app=com.mipay.wallet#/p/267',
}
import { firstUpperCase } from '@/common/util'
export default {
    name: 'Header',
    data () {
        return {
            activedRoute: 'Home',
            helpLink: defaultHelpLink
        }
    },
    mounted () {
        this.reload()
    },
    methods: {
        link(name) {
            this.helpLink = helpLinkObj[name] || defaultHelpLink
            this.activedRoute = name
            this.$router.push({ name })
        },
        reload() {
            let hash = window.location.hash.slice(2)
            let hashName = hash ? firstUpperCase(hash) : 'Home'
            this.helpLink = helpLinkObj[name] || defaultHelpLink
            this.activedRoute = hashName
        }
    }
}
</script>

<style scoped>
.header{
    width: 100%;
    padding: 0 10vw;
    height: 5vw;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,0.06);
}
.header .logo{
    display: flex;
    align-items: center;
}
.header .logo img{
    width: 11.5vw;
}
.header ul{
    height: 4.7vw;
    line-height: 4.7vw;
}
.header ul li{
    float: left;
    margin: 0 1vw;
    font-size: 1vw;
    cursor: pointer;
}
.header ul li a{
    display: inline-block;
    text-decoration: none;
    color: #000;
}
.header ul li.selected{
    border-bottom: 3px solid #116EFF;
    font-weight: bold;
}
.sub-header {
  width: 100%;
  padding: 0 10vw;
  height: 4vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sub-header h3 {
  font-weight: normal;
}
.sub-header .cards li {
  float: left;
  margin: 0 1vw;
  font-size: .9vw;
  cursor: pointer;
}
.sub-header .cards li.active {
  color: #ff6700; 
}
</style>
