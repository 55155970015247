import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import sensors from "sa-sdk-javascript"
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '智慧出行，便捷支付'
    }
  },
  {
    path: '/busCard',
    name: 'BusCard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/BusCard.vue'),
    meta: {
      title: '手机就是交通卡'
    }
  },
  {
    path: '/mipay',
    name: 'Mipay',
    component: () => import('../views/Mipay.vue'),
    meta: {
      title: '手机就是银行卡'
    }
  },
  {
    path: '/doorCard',
    name: 'DoorCard',
    component: () => import('../views/DoorCard.vue'),
    meta: {
      title: '手机就是门卡'
    }
  },
  {
    path: '/car',
    name: 'Car',
    component: () => import('../views/Car.vue'),
    meta: {
      title: '手机就是车钥匙'
    }
  },
  {
    path: '/ceb',
    name: 'Ceb',
    component: () => import('../views/Ceb.vue'),
    meta: {
      title: '小米信用卡'
    }
  },
  {
    path: '/gf',
    name: 'Gf',
    component: () => import('../views/Gf.vue'),
    meta: {
      title: '小米信用卡'
    }
  },
  // {
  //   path: '/creditCard',
  //   name: 'CreditCard',
  //   component: () => import('../views/CreditCard.vue'),
  //   meta: {
  //     title: '小米信用卡'
  //   }
  // },
  {
    path: '/cards',
    name: 'Cards',
    component: () => import('../views/Cards.vue'),
    meta: {
      title: '智慧出行，便捷支付'
    }
  },
  {
    path: '/devices',
    name: 'Devices',
    component: () => import('../views/Devices.vue'),
    meta: {
      title: '智慧出行，便捷支付'
    }
  },
  {
    path: '/banks',
    name: 'Banks',
    component: () => import('../views/Banks.vue'),
    meta: {
      title: '智慧出行，便捷支付'
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
router.afterEach(() => {
  window.scrollTo(0,0); //切换路由之后滚动条始终在最顶部
  Vue.nextTick(() => {
    //注意： vue下因为首页打开时候就会默认触发页面更新，所以需要去掉默认加的 sa.quick('autoTrack')。
		sensors.quick("autoTrackSinglePage");
	});
});

export default router
