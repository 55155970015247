// 获取url中的参数
export const getQueryString = (search, name) => {
    var reg = new RegExp('(^|&?)' + name + '=([^&#/]*)', 'i');
    search = search || window.location.href;
    var r = search.substr(1).match(reg);
    if (r != null) {
      return decodeURIComponent(r[2])
    }
    return null
};
// 首字母大写
export const firstUpperCase = ([first, ...rest]) => first.toUpperCase() + rest.join('')