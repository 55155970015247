<template>
    <div class="footer">
        <div class="info white">
            <p class="address">客服电话：400-100-5678<br/>联系地址：北京市海淀区西二旗中路33号院小米科技园</p>
            <p class="address">mipay.com 京ICP证140772号 <a class="beian" href="https://beian.miit.gov.cn/#/Integrated/index">京ICP备14041161号-1</a> 京公网安备11010802020135号</p>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    // msg: String
  }
}
</script>

<style scoped>
.footer{
    display: flex;
    justify-content: space-between;
    background-color: black;
    padding: 7vw 13vw;
}
.footer .info .address{
    margin-top: 1.7vw;
    font-size: .95vw;
    opacity: 0.8;
    line-height: 1.6vw;
}
.footer .info .address .beian{
    color: #fff;
    text-decoration: underline;
}
</style>
