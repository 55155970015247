import sensors from "sa-sdk-javascript";
import { getQueryString } from "./util.js"

const channelid = getQueryString(undefined, "channelid") || '';
const serverUrl = process.env.NODE_ENV === 'production' ? 'https://data.help.jr.mi.com/sa?project=xiaomilicai_test' : 'http://data.help.jr.mi.com/sa'
export const sensorsSdk = () => {
    sensors.init({
        server_url: serverUrl,
        heatmap: {
            //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
            clickmap: 'default',
            //是否开启触达注意力图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
            scroll_notice_map: 'not_collect'
        },
        show_log: false
    });
    if (channelid) {
        sensors.registerPage({
            channelid: channelid,
        });
    }
    // sensors.quick('autoTrack'); //用于采集 $pageview 事件。
}
// 在自定义事件添加自定义属性 -- 埋点到"创新支付官网"
export const quickCustomPropToTsmWebsite = (obj) => {
    // {
    //     websiteName: '',   websiteName-页面名称
    //     websiteClick: ''   websiteClick-点击项
    // }
    sensors.track('tsmWebsite', obj);
};