<template>
  <div class="warp">
    <div>
      <section class="mipay">
        <div class="img-bg"></div>
        <div class="content">
          <h3>多功能NFC<br>解锁智慧出行方式</h3>
          <span class="desc" >小米手机多功能NFC聚合了交通出行、便捷支付、生活服务等多种场景，致力于为用户实现“一机在手，出行无忧”的智能便捷生活，在钱包App（原小米钱包App）开通后，即可使用交通卡、门卡、Mi Pay、车钥匙、校园卡等功能。</span>
        </div>
      </section>
      <section class="bus-card">
        <div class="img-bg"></div>
        <div class="content text-right white">
          <p class="title">
            手机就是交通卡
            <br>碰一碰就乘车
          </p>
          <span class="desc">在钱包App开通交通卡后，即可拿起手机，一碰乘车。刷卡无需解锁/无需打开App/无需联网，同享实体卡乘车优惠，换手机支持免费移卡。现已支持全国300多个城市出行。</span>
        </div>
      </section>
      <section class="can-pay">
        <div class="img-bg"></div>
        <div class="content">
          <p class="title">
            手机就是银行卡
            <br>无需打开App就支付
          </p>
          <span class="desc">在钱包App中绑定银行卡，就可以使用Mi Pay进行便捷支付。付款时，双击电源键即可调出刷卡/二维码主扫/被扫界面，无需打开App就支付。硬件级金融防护支付便捷又安全。</span>
        </div>
      </section>
      <section class="door-card">
        <div class="img-bg"></div>
        <div class="content text-right">
          <p class="title">
            手机就是门卡
            <br>碰一碰就开门
          </p>
          <span class="desc">在钱包App开通门卡后，即可拿起手机，一碰开门。刷卡无需解锁/无需打开App/无需联网，拥有个性化卡面，支持云端备份卡片并迁移至新设备中，覆盖社区、公司等多场景，一部手机代替所有门禁卡。</span>
        </div>
      </section>
      <section class="school-card">
        <div class="img-bg"></div>
        <div class="content white">
          <p class="title">
            手机就是校园卡  畅行智能校园
          </p>
          <span class="desc">用户通过校方应用/小程序开通NFC校园卡功能，即可使用手机代替校园卡，畅行校园。</span>
        </div>
      </section>
      <section class="car-key">
        <div class="img-bg"></div>
        <div class="content white">
          <p class="title">
            手机就是车钥匙<br>解锁智能出行
          </p>
          <span class="desc">在钱包App创建数字车钥匙后，钱包内会生成对应车钥匙卡片，使用时无需实体车钥匙、无需解锁手机、无需打开应用。</span>
        </div>
      </section>
      <section class="choose-card">
        <div class="img-bg"></div>
        <div class="content">
          <p class="title">
            智能选卡
            <br>一触即达
          </p>
          <span class="desc">根据不同场景自动选择交通卡、门卡、车钥匙中正确的卡片刷卡，免除选卡烦恼</span>
          <span class="desc">*目前仅支持内置高通芯片设备</span>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { quickCustomPropToTsmWebsite } from "@/common/sensorsSdk";

export default {
  name: "Home",
  mounted() {
    quickCustomPropToTsmWebsite({ websiteName: "概览" });
  }
};
</script>

<style scoped>
.warp section .img-bg {
  width: 100%;
  height: 41vw;
}
.warp section.mipay .img-bg {
  background: url(https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/home_v2/mipay.jpg) no-repeat;
  background-size: cover;
}
.warp section.bus-card .img-bg {
  background: url(https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/home_v2/bus-card.jpg) no-repeat;
  background-size: cover;
}
.warp section.choose-card .img-bg {
  background: url(https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/home_v2/choose-card.jpg) no-repeat;
  background-size: cover;
}
.warp section.school-card .img-bg {
  background: url(https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/home_v2/school-card.jpeg) no-repeat;
  background-size: cover;
}
.warp section.car-key .img-bg {
  background: url(https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/home_v2/car-key.jpg) no-repeat;
  background-size: cover;
}
.warp section.can-pay .img-bg {
  background: url(https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/mipay_v2/mipay.jpg)
    no-repeat;
  background-size: cover;
}
.warp section.door-card .img-bg {
  background: url(https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/home_v2/door-card.jpg)
    no-repeat;
  background-size: cover;
}
.warp section .content {
  z-index: 9;
  position: absolute;
  top: 16vw;
  left: 15vw;
  width: 30vw;
}
.warp section .content h3 {
  font-size: 2.5vw;
  font-weight: 500;
}

.warp section .content p.title {
  font-size: 2vw;
  font-weight: 500;
}
.warp section .content span.desc {
  width: 80%;
  display: block;
  font-size: 0.9vw;
  margin-top: 0.5vw;
}
</style>