<template>
  <div>
    <Header/>
    <router-view/>
    <Footer/>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    Header,
    Footer
  },
  mounted() {}
};
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  min-width: 1260px;
  width: 100vw;
  height: 100vh;
  font-family: "MiSans","MI Lan Pro","serif" !important;
  color: #000 !important;
}
ul {
  list-style: none;
}
.text-right {
  right: 15vw;
  left: unset !important;
}
.white {
  color: #fff;
}
.grey {
  background: #fbfbfb;
}
.warp{
  width: 100%;
}
.warp section{
  position: relative;
  width: 100%;
  overflow-x: hidden;
}
/* 底部说明 */
.explain {
  font-size: 0.9vw;
  width: 60%;
  margin: 5vw auto;
  list-style: disc;
  letter-spacing: 0.5px;
}
.explain li {
  margin-bottom: 0.38vw;
}
</style>
